<template>
  <div>
    <div class="form-container" v-if="status === 'initial'">
      <h1>Motivaition</h1>
      <form @submit.prevent="handleSubmit" @keydown.enter.prevent="">
        <div class="form-group">
          <label for="name">Name:</label>
          <input type="text" id="name" v-model="name" />
        </div>
        <div class="form-group">
          <label for="email">Email:</label>
          <input type="email" id="email" v-model="email" />
        </div>
        <div class="form-group">
          <label for="goals">Goals:</label>
          <div class="goals-input">
            <input type="text" v-model="newGoal" @keyup.enter="addGoal" @blur="addGoal"
              placeholder="Add a goal and press Enter" />
            <button type="button" @click="addGoal">➕</button>
          </div>
          <ul class="goals-list">
            <li v-for="(goal, index) in goals" :key="index">
              {{ goal }}
              <button type="button" @click="deleteGoal(index)">Delete</button>
            </li>
          </ul>
        </div>
        <div class="form-group">
          <label for="frequency">Frequency:</label>
          <input type="range" id="frequency" v-model="frequency" min="1" max="5" />
          <span>{{ frequencyLabels[frequency - 1] }}</span>
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
    <div v-if="status === 'loading'" class="splash-container">
      <div class="loader"></div>
    
    </div>

    <div v-if="status === 'success'" class="splash-container success">
      <div class="icon-container">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-check" width="100"
          height="100" viewBox="0 0 24 24" stroke-width="2" stroke="green" fill="none" stroke-linecap="round"
          stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <circle cx="12" cy="12" r="9" />
          <path d="M9 12l2 2l4 -4" />
        </svg>
      </div>
      <h1>Account Created</h1>
      <p>Your account has been successfully created</p>
    </div>

    <div v-if="status === 'error'" class="splash-container error">
      <div class="icon-container">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-x" width="100" height="100"
          viewBox="0 0 24 24" stroke-width="2" stroke="red" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <circle cx="12" cy="12" r="9" />
          <path d="M10 10l4 4m0 -4l-4 4" />
        </svg>
      </div>
      <h1>Creation Failed</h1>
      <p>There was an error creating your account. Please try again later.</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'SignupComponent',
  setup() {
    const status = ref<'initial' |'loading' | 'success' | 'error'>('initial');


    const name = ref<string>('');
    const email = ref<string>('');
    const newGoal = ref<string>('');
    const goals = ref<string[]>([]);
    const frequency = ref<number>(2);
    const frequencyLabels = [
      'Rarely',
      'Occasionally',
      'Sometimes',
      'Often',
      'Very Often'
    ];

    const addGoal = () => {
      if (newGoal.value.trim()) {
        goals.value.push(newGoal.value.trim());
        newGoal.value = '';
      }
    };

    const deleteGoal = (index: number) => {
      goals.value.splice(index, 1);
    };

    const handleSubmit = async () => {
      status.value = 'loading';
      try {
        const response = await fetch("/api/signup", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
            // You can add other headers if needed
          },
          body: JSON.stringify({
            name: name.value,
            email: email.value,
            goals: goals.value,
            frequency: 6 - frequency.value
          })
        });

        if (!response.ok) {
          status.value = 'error';
          throw new Error('Network response was not ok');
        } else {
          status.value = 'success'
          triggerWelcomeMail();
        }
      } catch (error) {
        status.value = 'error';
        console.error('There was a problem with the fetch operation:', error);
      }

    }
    const triggerWelcomeMail = async () => {
      try {
        const response = await fetch("/api/email", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
            // You can add other headers if needed
          },
          body: JSON.stringify({
            name: name.value,
            email: email.value
          })
        });

        if (!response.ok) {
          status.value = 'error';
          throw new Error('Network response was not ok');
        } else {
          status.value = 'success';
        }
      } catch (error) {
        status.value = 'error';
        console.error('There was a problem with the fetch operation:', error);
      }
    };

    return {
      status,
      name,
      email,
      newGoal,
      goals,
      frequency,
      frequencyLabels,
      addGoal,
      deleteGoal,
      handleSubmit,
    };
  },
});
</script>

<style scoped>
.form-container {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input[type="text"],
input[type="email"] {
  text-align: left;
  width: 100%;


}

input[type="range"] {
  padding: 8px 0px;
  width: 100%;
  box-sizing: border-box;
}

input[type="range"] {
  margin-top: 10px;
}

.goals-input {
  display: flex;
  gap: 10px;
}

.goals-input input {
  flex-grow: 1;
}

.goals-input button {
  color: transparent;
  text-shadow: 0 0 0 #007bff;
}

.goals-list {
  list-style-type: none;
  padding: 0;
  margin-top: 10px;
}

.goals-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #e1f5fe;
  padding: 5px 10px;
  margin-bottom: 5px;
  border-radius: 4px;
}

.goals-list li button {
  background: none;
  border: none;
  color: #ff0000;
  cursor: pointer;
  font-size: 14px;
}

button[type="submit"] {
  display: block;
  width: 100%;
  padding: 10px;
  border: none;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 20px;

}

button[type="submit"]:hover {
  background-color: #0056b3;
}

.splash-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

.icon-container {
  margin-bottom: 20px;
}

.success .icon {
  stroke: green;
}

.error .icon {
  stroke: red;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 20px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #007bff;
  box-shadow: 0 0 0 0 #007bff5f;
  animation: l1 1s infinite;
}
@keyframes l1 {
    100% {box-shadow: 0 0 0 30px #007bff5f}
}
</style>
