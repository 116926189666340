// src/store/index.ts

import { createStore } from 'vuex'




const store = createStore({
  state: {
    user: null,
  },
  mutations: {
    SET_USER_DATA(state, userData) {
        state.user = userData;
        localStorage.setItem('user', JSON.stringify(userData));
      },
      CLEAR_USER_DATA() {
        localStorage.removeItem('user');
        location.reload();
      },
  },
  actions: {
    increment({ commit }) {
      commit('increment')
    },
  },
  getters: {
    
  }
})

export default store
