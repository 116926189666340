<template>
  <div>
    <div v-if="status === 'initial'" class="form-container">
      <h1>Delete Your Account</h1>
      <p>We're sorry to see you go. Deleting your account will permanently remove all your data from our system. This action cannot be undone.</p>
      <p>If you're sure you want to delete your account, please click the button below:</p>
      <button @click="deleteAccount">Delete Account</button>
    </div>
    
    <div v-if="status === 'success'" class="splash-container success">
      <div class="icon-container">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-check" width="100" height="100" viewBox="0 0 24 24" stroke-width="2" stroke="green" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <circle cx="12" cy="12" r="9" />
          <path d="M9 12l2 2l4 -4" />
        </svg>
      </div>
      <h1>Account Deleted</h1>
      <p>Your account has been successfully deleted. We're sad to see you go!</p>
    </div>
    
    <div v-if="status === 'error'" class="splash-container error">
      <div class="icon-container">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-x" width="100" height="100" viewBox="0 0 24 24" stroke-width="2" stroke="red" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <circle cx="12" cy="12" r="9" />
          <path d="M10 10l4 4m0 -4l-4 4" />
        </svg>
      </div>
      <h1>Deletion Failed</h1>
      <p>There was an error deleting your account. Please try again later.</p>
    </div>
  </div>
</template>
  
  <script lang="ts">
  import { defineComponent, ref } from 'vue';
  
  export default defineComponent({
    name: 'DeleteAccountComponent',
    
    setup() {
      const status = ref<'initial' | 'success' | 'error'>('initial');

      const deleteAccount = async () => {
        if(location.href.split("=")[1] !== undefined){
        try {
          const response = await fetch(`/api/delete?id=${location.href.split("=")[1]}`,{
            method: 'GET'
          });
      
          if (!response.ok) {
          status.value = 'error';
          throw new Error(`HTTP error! Status: ${response.status}`);
        } else {
        console.log(`Account with ID ${location.href.split("=")[1]} deleted successfully`);
        status.value = 'success';
        }

      } catch (error) {
        console.error('Error deleting account:', error);
        status.value = 'error';
      }
    }
    status.value = 'error';

    };
  
      return {
        status,
        deleteAccount,
      };
    },
  });
  </script>
  
  <style scoped>
  .form-container {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  p {
    text-align: center;
    margin-bottom: 20px;
  }
  
  button {
    display: block;
    width: 100%;
    padding: 10px;
    border: none;
    background-color: #ff0000;
    color: white;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 20px;
  }
  
  button:hover {
    background-color: #cc0000;
  }

  .splash-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

.icon-container {
  margin-bottom: 20px;
}

.success .icon {
  stroke: green;
}

.error .icon {
  stroke: red;
}
  </style>
  