<template>
  <div class="form-container">
    <h1>Thanks for your feedback</h1>

    <!-- Show thank you message if submitted -->
    <div v-if="submitted">
      <p>Amazing! Thanks for making Motivaition better 🎉</p>
    </div>

    <!-- Show form if not submitted -->
    <form v-if="!submitted" @submit.prevent="handleSubmit">
      <div class="form-group">
        <label for="comments">Additional Comments:</label>
        <textarea id="comments" v-model="comments" placeholder="Leave your feedback here"></textarea>
      </div>
      <button type="submit">Submit</button>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';

export default defineComponent({
  name: 'FeedbackComponent',
  setup() {
    const feedbackType = ref<string | null>(null);
    const comments = ref<string>('');  
    const submitted = ref<boolean>(false); // Track whether form has been submitted

    const setFeedback = (type: string) => {
      feedbackType.value = type;
    };

    const handleSubmit = () => {
      console.log('Feedback Type:', feedbackType.value);
      console.log('Comments:', comments.value);
      // Handle form submission logic here

      // Simulate submission success
      setTimeout(() => {
        submitted.value = true;
        // Optionally, clear form inputs
        feedbackType.value = null;
        comments.value = '';
      }, 500); // Simulated delay
    };

    onMounted(async () => {
      console.log('Component is mounted');
      const modifiedUrl = location.href.replace(location.host, `${location.host}/api`);
      console.log(modifiedUrl);

      if(location.href.split("=")[1] !== undefined){
        try {
          const response = await fetch(modifiedUrl,{
            method: 'PUT'
          });
      
          if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        } else {
        console.log(`Sucessfully updated feedback`);
        }

      } catch (error) {
        console.error('Error deleting account:', error);
      }
    }
    });

    return {
      feedbackType,
      comments,
      setFeedback,
      handleSubmit,
      submitted,
    };
  },
});
</script>

<style scoped>
.form-container {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.thumbs-choice {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.thumbs-choice button {
  background: none;
  border: none;
  font-size: 2em;
  cursor: pointer;
}

.thumbs-choice button.selected {
  color: #007bff;
}

button[type="submit"] {
  display: block;
  width: 100%;
  padding: 10px;
  border: none;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 20px;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}
</style>
