import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'



const app = createApp(App)
// Register Vue Router and Vuex Store
app.use(router)
app.use(store)

// Check localStorage for user data on app creation
const userString = localStorage.getItem('user')
if (userString) {
  const userData = JSON.parse(userString)
  store.commit('SET_USER_DATA', userData)
}

// Mount the app
app.mount('#app')