import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import SignupComponent from '../views/SignupComponent.vue'
import DeleteComponent from '../views/DeleteComponent.vue'
import FeedbackComponent from '../views/FeedbackComponent.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/signup'
  },
  {
    path: '/signup',
    name: 'SignupComponent',
    component: SignupComponent
  },
  {
    path: '/delete',
    name: 'DeleteComponent',
    component: DeleteComponent
  },
  {
    path: '/feedback',
    name: 'FeedbackComponent',
    component: FeedbackComponent
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
